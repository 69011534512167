import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PropTypes from "prop-types";
import "github-markdown-css/github-markdown.css";
import gpResearcher from "../images/gp-researcher.png";
export default class Template extends React.Component {
    componentDidMount() {
        this.elementCounts = {};
        this.observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                let id = entry.target.getAttribute("id") || entry.target.dataset.headerId;
                const selector = `.docs-toc li a[href="#${id}"]`;
                if (entry.isIntersecting) {
                    if (this.elementCounts[id] === undefined || this.elementCounts[id] < 1) {
                        this.elementCounts[id] = 1;
                    } else {
                        this.elementCounts[id]++;
                    }
                    // Always add the selector to active when we observe an intersection
                    document.querySelector(selector)?.classList.add("active");
                } else {
                    if (this.elementCounts[id] === undefined) {
                        this.elementCounts[id] = 0;
                    } else if (this.elementCounts[id] > 0) {
                        this.elementCounts[id]--;
                    }
                    // Only remove active class when intersections are no longer observed
                    if (this.elementCounts[id] === 0) {
                        document.querySelector(selector)?.classList.remove("active");
                    }
                }
            });
        });
        const mdBody = document.querySelector(".markdown-body");
        const mdHeadings = mdBody.querySelectorAll("h1, h2, h3");
        mdHeadings.forEach((header) => {
            // Observe header, then consume siblings and add watcher
            this.observer.observe(header);
            let nextSib = header.nextElementSibling;
            while (nextSib) {
                // If it's not an h1, h2, h3 we attach the observer to the previous heading
                if (nextSib.tagName !== "H1" && nextSib.tagName !== "H2" && nextSib.tagName !== "H3") {
                    nextSib.dataset.headerId = header.id;
                    this.observer.observe(nextSib);
                    nextSib = nextSib.nextElementSibling;
                } else {
                    nextSib = null;
                }
            }
        });
    }

    componentWillUnmount() {
        this.observer?.disconnect();
        this.elementCounts = {};
    }

    render() {
        const { markdownRemark } = this.props.data;
        const { frontmatter, tableOfContents, html } = markdownRemark;
        return (
            <Layout>
                <SEO title="Home" />
                <div className="columns">
                    <div className="column m-4 is-one-fifth">
                        <aside className="docs-toc">
                            <img className="mb-4" src={gpResearcher} />
                            <p className="menu-label">{frontmatter.title}</p>
                            <div dangerouslySetInnerHTML={{ __html: tableOfContents }}></div>
                        </aside>
                    </div>
                    <div className="column is-three-fifths docs-container">
                        <div className="title">{frontmatter.title}</div>
                        <div className="subtitle">{frontmatter.description}</div>
                        <div>
                            {frontmatter.version && <span>Version {frontmatter.version} | </span>}Last updated{" "}
                            {frontmatter.date}
                        </div>
                        <div className="mb-4">
                            Help: <a href="mailto:support@galaxyworks.io">support@galaxyworks.io</a>
                        </div>
                        <div className="markdown-body" dangerouslySetInnerHTML={{ __html: html }} />
                    </div>
                    <div className="column is-hidden-touch is-hidden-desktop-only is-hidden-widescreen-only is-one-fifth"></div>
                </div>
            </Layout>
        );
    }
}

Template.propTypes = {
    data: PropTypes.object,
};

export const pageQuery = graphql`
    query ($slug: String!) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            tableOfContents(maxDepth: 3)
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                slug
                title
                description
                author
                version
            }
            fields {
                readingTime {
                    text
                }
            }
        }
    }
`;
